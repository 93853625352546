import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const NewUserRegisterForAnAssociation = () => {
  return (
    <Layout>
      <Seo
        title="How Can a New User Register for an Association?"
        description={`Setting up an Association Profile as a new user in the Synkli mobile app is quick and easy.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`new-user-register-for-an-association`} />
    </Layout>
  )
}

export default NewUserRegisterForAnAssociation
